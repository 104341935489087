/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function DuplicateIcon({ className }) {
  return (
    <SvgIcon viewBox="0 0 17 17" className={className} fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.62471 16.0004L14.1249 16.0004C15.5 16 16 15.5 16 14.1253L16 6.62506C16 5 15.6816 4.75 14.1249 4.75L6.62471 4.75C5 4.75 4.74965 5 4.74965 6.62506L4.74965 14.1253C4.74965 15.5 5 16.0004 6.62471 16.0004Z" stroke="#69767A" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M12.2461 4.75012L12.2461 2.87506C12.2461 1.29733 12 1 10.371 1L4 1L2.87404 1C1.5 1 1 1.5 0.998984 2.87506L0.998984 2.8783L1 10.5C1 12 1.5 12.2504 2.887 12.2504L4.74586 12.2504" stroke="#69767A" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <path d="M13.1875 10.375L7.56232 10.375" stroke="#69767A" strokeWidth="1.1" strokeLinecap="square" strokeLinejoin="round" fill="none"/>
      <path d="M10.375 13.1875L10.375 7.56232" stroke="#69767A" strokeWidth="1.1" strokeLinecap="square" strokeLinejoin="round" fill="none"/>
    </SvgIcon>
  );
}
