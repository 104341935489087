/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '12px',
    height: '12px',
  },
}));

export default function DeletePageIcon() {
  const classes = useStyles();
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" className={classes.root} fill="none">
      <path d="M9.28115 2.91228V4.62727H10.4622V2.91228H12.161V1.80703H10.4622V0.094669H9.28115V1.80703H7.51456V2.91228H9.28115ZM2.05635 12.0556H10.152C11.4156 12.0556 12.161 11.3337 12.161 9.92455V5.25248H10.9554V9.84789C10.9554 10.537 10.5753 10.8815 10.108 10.8815H2.10035C1.9586 10.897 1.81511 10.8808 1.6807 10.8341C1.5463 10.7873 1.4245 10.7113 1.32449 10.6118C1.22449 10.5122 1.1489 10.3917 1.10342 10.2594C1.05795 10.1271 1.04378 9.98636 1.06199 9.84789V2.22571C1.0436 2.08667 1.05785 1.94536 1.10366 1.81253C1.14947 1.6797 1.22563 1.55885 1.32635 1.45918C1.42707 1.35951 1.5497 1.28364 1.68489 1.23736C1.82008 1.19107 1.96428 1.17558 2.10651 1.19207H7.23349V0.605917V0.019761H2.06251C1.76565 -0.0224575 1.46284 0.00318164 1.17779 0.094669C0.892746 0.186156 0.633213 0.341007 0.419527 0.547089C0.20584 0.753172 0.0438042 1.00489 -0.0538865 1.28252C-0.151577 1.56014 -0.182269 1.85614 -0.143557 2.14732V9.92627C-0.182718 10.2177 -0.152362 10.514 -0.0548717 10.7919C0.0426189 11.0699 0.204592 11.3219 0.418335 11.5283C0.632078 11.7347 0.891776 11.8898 1.17705 11.9814C1.46232 12.0731 1.7654 12.0987 2.06251 12.0564L2.05635 12.0556Z" fill="#808F94"/>
    </SvgIcon>
  );
}

