/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function DeleteIcon({ fill, viewBox, className }) {
  return (
    <SvgIcon viewBox={viewBox || '0 0 18.008 20.654'} className={className}>
      <path
        id='Контур_13486'
        data-name='Контур 13486'
        d='M6.558.625l.635-13.4H8.276a.738.738,0,0,0,.732-.732.74.74,0,0,0-.732-.742H4.087v-1.338a2.162,2.162,0,0,0-2.393-2.236H-1.7A2.162,2.162,0,0,0-4.1-15.586v1.338h-4.17A.74.74,0,0,0-9-13.506a.738.738,0,0,0,.732.732h1.084L-6.548.635a2.154,2.154,0,0,0,2.314,2.2H4.243A2.172,2.172,0,0,0,6.558.625ZM-2.534-15.5a.875.875,0,0,1,.938-.889H1.6a.875.875,0,0,1,.938.889v1.25H-2.534ZM-4.077,1.357A.94.94,0,0,1-5.024.42l-.635-13.193H5.63L5.024.42a.916.916,0,0,1-.947.938ZM2.739-.225A.6.6,0,0,0,3.306-.85l.273-9.619a.583.583,0,0,0-.557-.635.614.614,0,0,0-.566.625L2.183-.859A.577.577,0,0,0,2.739-.225Zm-5.469,0a.583.583,0,0,0,.557-.635l-.273-9.619a.621.621,0,0,0-.566-.625.572.572,0,0,0-.557.635L-3.3-.85A.588.588,0,0,0-2.729-.225ZM0-.225A.611.611,0,0,0,.581-.859v-9.609A.611.611,0,0,0,0-11.1a.611.611,0,0,0-.576.635V-.859A.611.611,0,0,0,0-.225Z'
        transform='translate(8.999 17.822)'
        fill={fill || '#808f94'}
      />
    </SvgIcon>
  );
}
