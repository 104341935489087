import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const RupExcelIcon = ({ className }) => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <SvgIcon viewBox='0 0 78.24 53.08' className={className}>
    <g id="Layer_1-2" data-name="Layer 1-2">
      <g>
        <path fill="#1f305c" d="M67.54,31.06c6.1-2.49,9.61-7.23,10.45-14.08l.06-.51c.4-3.22.17-5.92-.68-8.02-.81-2-2.17-3.53-4.04-4.54-2.02-1.09-4.28-1.85-6.71-2.27-2.5-.43-5.54-.64-9.05-.64-4.44,0-9.2.11-14.16.32l-6.34,51.76h7.37l2.45-20h6.1c2.19,0,3.77-.02,4.69-.07l.87-.04.38.8,9.15,19.31h8.09l-9.81-21.56,1.16-.47h.02ZM54.66,26.79c-2.57,0-4.45-.03-5.75-.08l-1.46-.06,2.52-20.57h1.08c2.13,0,3.74.01,4.8.04,1.06.03,2.37.06,3.92.11,1.65.05,2.83.16,3.6.32.7.15,1.57.37,2.59.65,1.25.35,2.13.83,2.68,1.46.41.47.85,1.04,1.29,1.69.55.82.86,1.76.93,2.78.06.85.02,1.88-.13,3.05l-.05.44c-.47,3.84-2.04,6.55-4.65,8.05-2.46,1.41-6.28,2.12-11.36,2.12"/>
        <path fill="#1f305c" d="M19.81,16.26c-.34,2.72-2.83,4.93-5.55,4.93s-4.64-2.21-4.3-4.93c.34-2.72,2.82-4.92,5.55-4.92s4.65,2.21,4.3,4.92"/>
        <path fill="#1f305c" d="M29.97,27.58c-.34,2.72-2.82,4.93-5.54,4.93s-4.65-2.2-4.31-4.93c.34-2.72,2.82-4.92,5.54-4.92s4.65,2.21,4.3,4.92"/>
        <path fill="#1f305c" d="M31.39,16.26c-.34,2.72-2.82,4.93-5.54,4.93s-4.65-2.21-4.3-4.93c.34-2.72,2.82-4.92,5.54-4.92s4.65,2.21,4.3,4.92"/>
        <path fill="#1f305c" d="M9.89,4.93c-.34,2.72-2.82,4.93-5.54,4.93S-.3,7.65.04,4.93C.39,2.21,2.86,0,5.58,0s4.65,2.21,4.31,4.93"/>
        <path fill="#1f305c" d="M21.35,4.93c-.34,2.72-2.83,4.93-5.55,4.93s-4.64-2.21-4.3-4.93S14.33,0,17.05,0s4.64,2.21,4.3,4.93"/>
        <path fill="#1f305c" d="M32.82,4.93c-.34,2.72-2.82,4.93-5.54,4.93s-4.65-2.21-4.3-4.93c.34-2.72,2.82-4.93,5.54-4.93s4.65,2.21,4.3,4.93"/>
      </g>
    </g>
  </SvgIcon>
);

export default RupExcelIcon;
