export const GET_KIOSKS = 'GET_KIOSKS';
export const GET_KIOSKS_SUCCESS = 'GET_KIOSKS_SUCCESS';
export const GET_KIOSKS_ERROR = 'GET_KIOSKS_ERROR';

export const POST_KIOSK = 'POST_KIOSK';
export const POST_KIOSK_SUCCESS = 'POST_KIOSK_SUCCESS';
export const POST_KIOSK_ERROR = 'POST_KIOSK_ERROR';

export const PATCH_KIOSK = 'PATCH_KIOSK';
export const PATCH_KIOSK_SUCCESS = 'PATCH_KIOSK_SUCCESS';
export const PATCH_KIOSK_ERROR = 'PATCH_KIOSK_ERROR';

export const POST_KIOSK_CHANGE_PASSWORD = 'POST_KIOSK_CHANGE_PASSWORD';
export const POST_KIOSK_CHANGE_PASSWORD_SUCCESS = 'POST_KIOSK_CHANGE_PASSWORD_SUCCESS';
export const POST_KIOSK_CHANGE_PASSWORD_ERROR = 'POST_KIOSK_CHANGE_PASSWORD_ERROR';

export const DELETE_KIOSK = 'DELETE_KIOSK';
export const DELETE_KIOSK_SUCCESS = 'DELETE_KIOSK_SUCCESS';
export const DELETE_KIOSK_ERROR = 'DELETE_KIOSK_ERROR';

export const GET_SETTINGS_PHOTO_TAKE = 'GET_SETTINGS_PHOTO_TAKE';
export const GET_SETTINGS_PHOTO_TAKE_SUCCESS = 'GET_SETTINGS_PHOTO_TAKE_SUCCESS';
export const GET_SETTINGS_PHOTO_TAKE_ERROR = 'GET_SETTINGS_PHOTO_TAKE_ERROR';

export const POST_SETTINGS_PHOTO_TAKE = 'POST_SETTINGS_PHOTO_TAKE';
export const POST_SETTINGS_PHOTO_TAKE_SUCCESS = 'POST_SETTINGS_PHOTO_TAKE_SUCCESS';
export const POST_SETTINGS_PHOTO_TAKE_ERROR = 'POST_SETTINGS_PHOTO_TAKE_ERROR';

export const GET_KIOSKS_USERS = 'GET_KIOSKS_USERS';
export const GET_KIOSKS_USERS_SUCCESS = 'GET_KIOSKS_USERS_SUCCESS';
export const GET_KIOSKS_USERS_ERROR = 'GET_KIOSKS_USERS_ERROR';

export const PATCH_UPDATE_STATUS = 'PATCH_UPDATE_STATUS';
export const PATCH_UPDATE_STATUS_SUCCESS = 'PATCH_UPDATE_STATUS_SUCCESS';
export const PATCH_UPDATE_STATUS_ERROR = 'PATCH_UPDATE_STATUS_ERROR';

export const PATCH_UPDATE_RFID = 'PATCH_UPDATE_RFID';
export const PATCH_UPDATE_RFID_SUCCESS = 'PATCH_UPDATE_RFID_SUCCESS';
export const PATCH_UPDATE_RFID_ERROR = 'PATCH_UPDATE_RFID_ERROR';

export const PATCH_UPDATE_PIN_CODE = 'PATCH_UPDATE_PIN_CODE';
export const PATCH_UPDATE_PIN_CODE_SUCCESS = 'PATCH_UPDATE_PIN_CODE_SUCCESS';
export const PATCH_UPDATE_PIN_CODE_ERROR = 'PATCH_UPDATE_PIN_CODE_ERROR';

export const PATCH_UPDATE_PIN_CODES = 'PATCH_UPDATE_PIN_CODES';
export const PATCH_UPDATE_PIN_CODES_SUCCESS = 'PATCH_UPDATE_PIN_CODES_SUCCESS';
export const PATCH_UPDATE_PIN_CODES_ERROR = 'PATCH_UPDATE_PIN_CODES_ERROR';

export const GET_PIN_CODE_GENERATE = 'GET_PIN_CODE_GENERATE';
export const GET_PIN_CODE_GENERATE_SUCCESS = 'GET_PIN_CODE_GENERATE_SUCCESS';
export const GET_PIN_CODE_GENERATE_ERROR = 'GET_PIN_CODE_GENERATE_ERROR';
